export default {
  error_msg: {
    transactions_error:
      "Recording TRANSACTION_NAME of AMOUNT CURRENCY for MEMBER_NAME has failed. What do you want to do with the transaction?",
  },
  countries: {
    Niger: "Niger",
    Tanzania: "Tanzania",
    Rwanda: "Rwanda",
    Zambia: "Zambia",
    Honduras: "Honduras",
    Nigeria: "Nigeria",
    Lebanon: "Lebanon",
    Mozambique: "Moçambique",
    Moçambique: "Moçambique",
    Ghana: "Ghana",
    Syria: "Syria",
    Palestine: "Palestine",
    Kenya: "Kenya",
  },
  title_copy: {
    status: "status",
    version_msg: "You're using the test version of vsla.online",
    cycles_title: "Saving cycles",
    header_login: "LOGIN",
    header_signup: "SIGNUP",
    create_group: "CREATE GROUP",
    group_finance: "Welfare Fund:",
    group_header: "GROUP",
    loading: "Loading...",
    updating: "Updating and activating ....",
    check_status: "checking group status",
    no_vsla: "No group found",
    create_meeting: "CREATE MEETING",
    meeting_members: "Members",
    meeting: "MEETING",
    prev_meeting: "PREVIOUS MEETINGS",
    offline_meeting: "Offline meeting",
    add_member: "PERSONAL INFORMATION",
    buy_shares: "BUY SHARES",
    sell_shares: "SELL SHARES",
    fine: "PAY FINE",
    welfare: "WELFARE CONTRIBUTION",
    welfare_request: "WELFARE REQUEST",
    membership_fee: "MEMBERSHIP FEE",
    loan: "LOAN",
    funds: "FUNDS",
    more_details: "More Details",
    vsla_actions: "Group Actions",
    mem_actions: "Member Actions",
    memberlist_overview: "LIST",
    mem_menu: "ADD MEMBERS",
    loan_repayment: "LOAN REPAYMENT",
    loan_writeoff: "WRITE OFF LOAN",
    outstanding_loan: "Unpaid Loan:",
    outstanding_loan_interest: "Unpaid loan interest:",
    outstanding_service_charge: "Unpaid loan interest:",
    attendees: "Meeting Attendees",
    share_value: "Share Value",
    cashOutType: "Cash out type",
    settings: "SETTINGS",
    current_savings: "Savings",
    cycle_duration: "Cycle Duration",
    addFundAccount: "ADD FUND ACCOUNT",
    supported_accounts: "Supported accounts",
    lastTransaction: "Last transaction",
    otherIncome: "GROUP INCOME",
    otherExpenses: "GROUP EXPENSES",
    cashOut: "CASH OUT",
    warning: "Warning",
    end_of_cycle_cash_out: "End-cycle cash out",
    reminder: "Reminder",
  },

  menu: {
    vlsa: "VSLA",
    group: "Group",
    meeting: "Meeting",
    create_vsla: "Create group",
    vsla_overview: "Group Overview",
    new_meeting: "New Meeting",
    prev_meeting: "Previous Meetings",
    offline_meeting: "Offline meeting",
    vsla_saving: "Savings Fund:",
    vlsa_loan: "Total Loans:",
    vlsa_balance: "Actual Balance:",
    funds: "Funds",
    members: "Members",
    members_overview: "Members Overview",
    add_member: "Add Member",
    end_meeting: "End Meeting",
    current_meeting: "Current Meeting",
    settings: "Settings",
    options: "Options",
    language: "Language",
    close: "Close",
    savingsFund: "Savings fund",
    welfareFund: "Welfare Fund",
    loanInterestFund: "Loan Interest Fund",
    fineFund: "Fine Fund",
    membershipFund: "Membership Fund",
    end_of_cycle_cash_out: "End of cycle cash out",
  },

  menu_mem: {
    overview: "Members Overview",
    welfare: "Welfare Contribution",
    welfare_request: "Welfare Request",
    buy_shares: "Buy Shares",
    create_loan: "Create Loan",
    repay_loan: "Repay Loan",
    write_off_loan: "Write-off Loan",
    writeoff_loan: "Write-off Loan",
    pay_fine: "Pay fine",
    membership_fee: "Membership Fee",
    sell_shares: "Sell Shares",
    transaction_amount: "Transaction Amount",
    other_income: "Group Income",
    other_expenses: "Group Expenses",
    cashout: "Cash out",
    group_income: "Group Income",
    group_expense: "Group Expenses",
    fine_payment: "Fine Payment",
    welfare_contribution: "Welfare Contribution",
    loan_repayment: "Loan Repayment",
    loan_interest_repayment: "Loan Interest Repayment",
    fine_payment_reversal: "Fine Payment Reversed",
    buy_shares_reversal: "Buy Shares Reversed",
    welfare_contribution_reversal: "Welfare Contribution Reversed",
    sell_shares_reversal: "Sell Shares Reversed",
    loan_interest_repayment_reversal: "Loan Interest Repayment Reversed",
    welfare_request_reversal: "Welfare Request Reversed",
    membership_fee_reversal: "Membership Fee Reversed",
    savings_cash_out: "Savings cashout",
    midCycle_Cashout: "Mid-cycle cash out",
    loan_repayment_reversal: "Loan Repayment Reversed",
    group_income_fund: "Group income - Savings",
    group_income_fine: "Group income - Fine",
    group_income_welfare: "Group income - Welfare",
    group_expense_fund: "Group expense - Savings",
    group_expense_fine: "Group expense - Fine",
    group_expense_welfare: "Group expense - Welfare",
  },

  mem_overview: {
    welfare: "Welfare Contribution:",
    shares_in_francs: "Shares in Francs:",
    loan: "Loan Amount:",
    shares_in_numbers: "Shares in Numbers:",
    welfare_total: "Welfare:",
    shares_total: "Member's shares:",
  },

  header: {
    home: "Home",
    vsla: "Group",
    logout: "Logout",
    whats_new: "What's New",
    tutorials: "Tutorials",
    legal: "Legal",
  },

  placeholder_copy: {
    loader_msg1: "Setting group profile",
    loader_msg2: "Creating accounting entries",
    loader_msg3: "Allocating accounts to group",
    loader_msg4: "Assigning Secretary",
    add_account_loader_msg: "Creating accounts, final step",
    group_loader_msg: "Creating group, wait a moment",
    nationality: "Nationality",
    gender: "Gender",
    female: "Female",
    male: "Male",
    sms_code: "Verification code",
    submit: "Submit",
    income_amount: "amount...",
    login_email: "Phone Number",
    login_password: "Password...",
    login_submit: "Login",
    login_submitting: "Logging you in...",
    signUp_submit: "Sign Up",
    signUp_submitting: "Signing you up..",
    create_group_name: "Group name...",
    create_group_location: "Group location...",
    create_group_submit: "Create Group",
    create_fund_submit: "Create Fund",
    meeting_date: "Select date",
    meeting_submit: "Create Meeting",
    meeting_submitting: "Creating Meeting",
    member_submit: "Create Member",
    member_name: "Full name",
    member_phone: "Phone number",
    number_of_shares: "Number of shares...",
    shares_submit: "Buy Shares",
    share: "Share",
    loan_amount: "Loan amount...",
    loan_submit: "Create Loan",
    welfare_amount: "Welfare amount...",
    welfare_submit: "Pay Welfare",
    payment_amount: "Payment amount...",
    repay_submit: "Pay Loan",
    loan_writeoff: "Write-off Loan",
    share_value_amount: "Share value amount..",
    share_value_submit: "Update settings",
    create_group_share_value: "Group cycle share value",
    role_in_group: "Role in group",
    login_pin: "PIN",
    login_pin_confirm: "Confirm PIN",
    create_group_federation: "Federation...",
    savings_cycle: "Select savings cycle..",
    secretary_pay_interest: "Does Secretary pay interest for loans",
    pays_interest: "Does member pay loan interest for loans?",
    country: "Select country",
    date_of_birth: "Date of birth",
    add_secretary_header: "Adding Secretary",
    add_secretary_msg: "The secretary will be added first.",
    add_secretary_status: "Adding..",
    add_secretary: "Add Secretary",
    total_amount: "Total Amount",
    fine_amount: "fine amount....",
    fine_submit: "Pay Fine",
    membership_amount: "Membership fee...",
    membership_submit: "Pay Membership",
    sell_shares: "Sell Shares",
    membership_reverse: "Reverse",
    service_charge_amount: "Loan interest amount",
    forgot_pin: "Forgot your PIN?",
    account_associated: "Enter the phone number associated with your account",
    token_sent: "Enter the 4-digit code you have received through SMS.",
    resend_token: "Resend the code",
    submit_smscode: "Enter your new 4-digit PIN code",
    income_submit: "Cash Out",
    select_nationality: "Select Nationalities",
    next: "Next",
    cashingOut: "Cashing out...",
    finish: "Finish",
    currency: "Select currency",
    select: "Select",
    savings_amount: "Savings amount",
    omt_recipt_number: "OMT Receipt number",
    enter_recipt_number: "Enter receipt number",
    savings_withdrawal: "Savings withdrawal",
    project_name: "Project Name",
    retry: "Retry",
    remove: "Remove",
    secretary_creation_msg: "Secretary creation is currently in progress...",
    secretary_creation_failed_msg: "Secretary creation failed. Please retry.",
  },

  delete: "Delete Group",
  delete_member: "Delete Member",
  edit: "Edit Group",
  save: "Save",
  edit_member: "Edit Member",

  alert: {
    upload_error:
      "offline transaction upload encountered an error. Go to offline meeting record to see the error",
    upload_success:
      "Offline transactions have been uploaded and resolved. Check previous meetings records to see it",
    no_members: "There are no members in this group",
    no_savings_cash_out_personal:
      "Savings cash out locked, Uncleared personal loan",
    no_savings_cash_out_group:
      "Savings cash out locked, Uncleared loan in group",
    no_membership_cash_out_personal:
      "Membership cash out locked, Uncleared personal loan",
    no_membership_cash_out_group:
      "Membership cash out locked, Uncleared loan in group",
    no_fine_cash_out_personal: "Fine cash out locked, Uncleared personal loan",
    no_fine_cash_out_group: "Fine cash out locked, Uncleared loan in group",
    no_welfare_cash_out_personal:
      "Welfare cash out locked, Uncleared personal loan",
    no_welfare_cash_out_group:
      "Welfare cash out locked, Uncleared loan in group",
    cash_out_success: "All conditions have been meet to enable cashing out",
    success: "Success",
    error: "Error",
    cash_out_type_not_define:
      "Cash out type for this fund is not defined, update it from the settings menu",
    cannot_end_meeting: "Can't end the meeting while a transaction in progress",
    failed: "Failed",
    repay_loans_message: "Repay the loans these members have",
    cannot_cash_out: "Can't cash out while a transaction in progress",
    all_members_cashed_out: "All members have been cashed out",
    no_internet: "No Internet",
    uploading_transactions: "Uploading transactions...",
    member_cashed_out: "Member has been cashed out",
    member_pending_loan: "Member has a loan they need to repay",
    cannot_delete_member_cashout_msg:
      "Can't delete member while cashout in progress",
    cannot_delete_member_secretary_msg: "Can't delete the App Secretary",
    cannot_delete_member_has_balance_msg:
      "Can't delete, member needs to be cashed out first",
  },

  dropdowns: {
    secretary: "Secretary",
    keyHolder: "Key Holder",
    chairperson: "Chairperson",
    box_keeper: "Box keeper",
    money_counter: "Money counter",
    member: "Member",
    yes: "Yes",
    no: "No",
    nine_months: "9 months",
    six_months: "6 months",
    year: "1 year",
    establishment_date: "Establishment Date",
    USD: "USD",
    LBP: "LBP",
    business_development: "Business development",
    personal_emergency: "Personal emergency",
    other: "Other",
  },

  cards: {
    total_member: "Total Members",
    cycle_duration: "Cycle Duration",
    country: "Country",
    locality: "Locality",
    federation: "Federation",
    share_value: "Share Value",
    months: "months",
    created_at: "Created on",
    established_on: "Established on",
  },

  notifications: {
    group_exist_error:
      "Group name already taken. Please choose a different one",
    loan_error: "Error: Member has an active loan",
    edit_success: "Successfully Updated",
    edit_error: "Error Updating",
    signup_success: "Account created successfully",
    success: "Completed successfully",
    error: "Did not complete successfully",
    group_create_success: "Group successfully created",
    group_create_error: "Error creating group",
    activation_success: "Group successfully activated",
    activation_error: "Error activating group",
    meeting_end_success: "Meeting ended",
    meeting_end_error: "Error ending meeting",
    meeting_create_success: "Meeting started",
    meeting_create_error: "Meeting did not start",
    member_create_success: "Member successfully created",
    member_create_error: "Error creating member",
    meeting_no_member_warning:
      "Can not start meeting before adding members to VSLA",
    no_previous_meeting: "There are no previous meetings for this VSLA",
    no_transactions: "There are no transactions for this meeting",
    loan_amount_error: "Loan amount exceeds allowed amount",
    share_amount_error: "Shares amount exceeds allowed amount",
    share_value_loading: "Getting current shares value",
    cant_update_shares: "Meeting in progress can not update share value",
    member_phone_error: "Number in Use",
    savings_fund_mandatory: "Deselect unused funds. Savings funds is required",
    fund_create_success: "Fund successfully created",
    gdpr_warning:
      " By proceeding to create the account, you confirm that you are 18 years of age and above",
    can_not_request_welfare:
      "Current welfare balance is zero, request not possible",
    no_reversible_transactions: "No reversible transactions found",
    activate_reversible_transactions:
      "Check the box to activate reversal of transactions",
    welfare_amount_warning: "Amount exceeds allowed amount",
    pin_success: "PIN changed successfully",
    pin_error: "Enter correct code! Try again",
    cancel_txn: "Transaction cancelled",
    processing: "Processing Transaction",
    pay_out_standing_charge_first:
      "Service charge fee should be cleared before loan repayment",
    cant_delete_member_has_funds:
      "Member cannot be deleted if they have money left or while meeting is in progress",
    cant_delete_group_has_funds:
      "Group can not be delete if wallet is funded or meeting is in progress",
    end_of_cycle_cash_out_warning:
      "Once you've started end of cycle cashout, you won't be able to add any new transaction records until cash out is completed.",
    cash_out_success_msg: "Cash out completed",
    share_value_information:
      "You are starting a new saving cycle. Do you want to change the share value?",
    share_value_updated_msg: "Share value updated!",
    reversal: "Processing Reversal",
    mid_cycle_cash_out_warning:
      "Once you've started mid-cycle cashout, you won't be able to add any new transaction records until cash out is completed.",
    proceed_to_cashout_msg: "Write “cashout” to proceed",
    cashout: "cashout",
    incorrect_username_password_msg: "Incorrect username or password",
    contactHiveForSupportMsg:
      "Error sending code. Contact hiveonline for support",
  },

  badge: {
    meeting_on: "End Meeting",
  },

  span: {
    current_cycle: "Current Cycle",
    previous_cycles: "Previous cycles",
    transaction_status: "Transactions status",
    selectFund: "Select Fund",
    CashOutTypeSelect: "Select Cash Out Type",
    login_span: "Dont have an account ?",
    signup: "Sign up",
    signup_span: "Have an account already ?",
    login: "Login",
    welfare: "Welfare",
    savings: "Savings",
    fine: "Fine",
    endOfCycle: "End of cycle",
    midCycle: "Mid cycle",
    membership: "Membership",
    sharedBased: "Shares Based",
    equal: "Equal",
    refund: "Refund",
    selectCashOutType: "Select Group Cash Out Type",
    savingsFund: "Savings Fund",
    membershipFund: "Membership Fund",
    welfareFund: "Welfare Fund",
    fineFund: "Fine Fund",
    loanInterestFund: "Loan Interest Fund",
    equally: "Equally",
    fineCashOutType: "Fine Cash Out Type",
    loanServiceCharge: "Loan Service Charge",
    welfareCashOutType: "Welfare Cash Out Type",
    member_role: "Member Role",
    processing: "Processing",
    back: "Back",
    continue: "Continue",
    no_records_found: "No transactions records found",
    loan_purpose: "Loan purpose",
    write_loan_purpose: "Write the purpose of the loan",
    cancel: "Cancel",
    save: "Save",
    saving: "Saving...",
    bc_wallet: "BC Wallet",
    link: "Link",
  },
  banner: {
    install: "Install",
  },
  locale: {
    date: "en-EN",
  },
  memberActions: {
    confirm_message: `Delete member ?`,
  },
  cashOut: {
    settings_summary_title: "These are the cash-out types set for each fund:",
    savings: "Savings Fund",
    membership: "Membership Fund",
    welfare: "Welfare Fund",
    fine: "Fine Fund",
    loan_interest: "Loan interest fund",
    call_to_action: "You can change these settings",
    write_here: "Write here",
  },
  settings: {
    disclaimer:
      "you can only change cash-out types settings if no transaction has been added to current meeting",
  },
  status: {
    SUCCESSFUL: "SUCCESSFUL",
    PENDING: "PENDING",
    FAILED: "FAILED",
  },
  locations: {
    country: "Country",
    county: "County",
    province: "Province",
    region: "Region",
    district: "District",
    gh_district: "Metropolitan/Municipal/District",
    municipality: "Municipality",
    sub_county: "Sub-County",
    administrative_post: "Administrative post",
    locality: "Locality",
    select_country: "select country",
    select_county: "select county",
    select_sub_county: "select sub-county",
    select_province: "select province",
    select_region: "select region",
    select_district: "select district",
    select_municipality: "select municipality",
    select_administrative_post: "select administrative post",
    select_locality: "select address",
    neigbourhood_name: "Neighborhood/Street name",
  },
};
