export default {
  countries: {
    Niger: "Niger",
    Tanzania: "Tanzania",
    Rwanda: "Rwanda",
    Zambia: "Zambia",
    Honduras: "Honduras",
    Nigeria: "Nigeria",
    Lebanon: "Lebanon",
    Mozambique: "Moçambique",
    Moçambique: "Moçambique",
    Ghana: "Ghana",
    Syria: "Syria",
    Palestine: "Palestine",
  },
  title_copy: {
    status: "status",
    version_msg: "You're using the test version of vsla.online",
    cycles_title: "Saving cycles",
    header_login: "BUƊE",
    header_signup: "TABBATA",
    create_group: "RUBUTA SABUWA KUNGIYA",
    group_finance: "Kwandon Kuɗin Taimako:",
    group_header: "RUKUNONI",
    loading: "Shigad da labaru...",
    updating: "Sabuntawa da hwarhwardowa ...",
    check_status: "Dibin kamatar  kungiya da kyau",
    no_vsla: "Ba kungiya",
    create_meeting: "SHIRIYA TARO",
    meeting_members: "ƴan kungiya",
    meeting: "TARO",
    prev_meeting: "TARO BAYA",
    offline_meeting: "Offline meeting",
    add_member: "LABARUN KAN MUTUN",
    buy_shares: "BIYAN KOUDIN ZOUBI",
    sell_shares: "SELL SHARES",
    fine: "PAY FINE",
    welfare: "TARBACEN KUƊIN TAIMAKO",
    welfare_request: "WELFARE REQUEST",
    membership_fee: "MEMBERSHIP FEE",
    loan: "BASHI",
    funds: "BABBA JIMILLA KUƊI RUKUNI",
    more_details: "Ƙaruwa bayỳani daki-daki",
    vsla_actions: "Hannayen Rukunin",
    mem_actions: "Hannayen ƴan Rukuni",
    memberlist_overview: "TSARIN SUNAYE MUTANE",
    mem_menu: "ƘARA SUNAYEN ƳAN KUNGIYA",
    loan_repayment: "BIỲAN BASHI",
    loan_writeoff: "WRITE OFF LOAN",
    outstanding_loan: "Sauran Bashi:",
    attendees: "Mutanen taron",
    share_value: "Kimanin Hannu Guda",
    settings: "SHIRIYE-SHIRIYE",
    current_savings: "Kuɗin ajiya",
    cycle_duration: "Tsawon kewaya",
    addFundAccount: "ADD FUND ACCOUNT",
    supported_accounts: "Supported accounts",
    lastTransaction: "Last transaction",
    cashOut: "Retirer",
    warning: "Warning",
    end_of_cycle_cash_out: "End-cycle cash out",
    reminder: "Reminder",
  },

  menu: {
    vlsa: " Ma'agiya da Rancen Kuɗi",
    group: "Rukuni",
    meeting: "Taro",
    create_vsla: "Shigad da sabuwa kungiya",
    vsla_overview: "Labarun kan kungiya",
    new_meeting: "Sabon Taro",
    prev_meeting: "Tarurruka Baya",
    offline_meeting: "Offline meeting",
    vsla_saving: "Gidauniya ajiyayyen kuɗi:",
    vlsa_loan: "Jimilla Basussuka:",
    vlsa_balance: "Saura hwarin Kuɗi:",
    funds: "Babba Jimilla kuɗin rukunin",
    members: "ƴan kungiya",
    members_overview: "Labaru a Kan ƴan rukunin",
    add_member: "Ƙara sabuwa ƴar kungiya",
    end_meeting: "Ƙarewa Taro",
    current_meeting: "Taron Da ke  Gudana ỳanzu",
    settings: "Shiriye-shiriye",
    options: "Zaben abun yi",
    language: "Language",
    close: "Tsayad da aiki",
    savingsFund: "Savings fund",
    welfareFund: "Welfare Fund",
    loanInterestFund: "Loan Interest Fund",
    fineFund: "Fine Fund",
    membershipFund: "Membership Fund",
    end_of_cycle_cash_out: "End of cycle cash out",
  },

  menu_mem: {
    overview: "Labarun kan ƴan kungiya",
    welfare: "Tarbacen kudin Taimako",
    welfare_request: "Welfare Request",
    buy_shares: "Shiga zubi adashe",
    create_loan: "Shirin Bashi",
    repay_loan: "Biỳan Bashi",
    writeoff_loan: "Write-off Loan",
    pay_fine: "Pay fine",
    membership_fee: "Membership Fee",
    sell_shares: "Sell Shares",
    transaction_amount: "Transaction Amount",
    group_income: "Group Income",
    group_expense: "Group Expenses",
    fine_payment: "Fine Payment",
    welfare_contribution: "Welfare Contribution",
    loan_repayment: "LOAN REPAYMENT",
    loan_interest_repayment: "Loan Interest Repayment",
    fine_payment_reversal: "Fine Payment Reversed",
    buy_shares_reversal: "Buy Shares Reversed",
    welfare_contribution_reversal: "Welfare Contribution Reversed",
    sell_shares_reversal: "Sell Shares Reversed",
    loan_interest_repayment_reversal: "Loan Interest Repayment Reversed",
    welfare_request_reversal: "Welfare Request Reversed",
    membership_fee_reversal: "Membership Fee Reversed",
    savings_cash_out: "Savings cashout",
    midCycle_Cashout: "Mid-cycle cash out",
    group_income_fund: "Group income - Savings",
    group_income_fine: "Group income - Fine",
    group_income_welfare: "Group income - Welfare",
    group_expense_fund: "Group expense - Savings",
    group_expense_fine: "Group expense - Fine",
    group_expense_welfare: "Group expense - Welfare",
  },

  mem_overview: {
    welfare: "Tarbacen Kudin  Taimako:",
    shares_in_francs: "Jimilla Kudin Hannaye (a CFA):",
    loan: "Jimilla basussuka:",
    shares_in_numbers: "(Lissahin) Yawan Hannaye:",
    welfare_total: "Welfare:",
    shares_total: "Shares:",
  },

  header: {
    home: "Mazamni",
    vsla: "Kungiya",
    logout: "Tsaidar da aiki",
    whats_new: "What's New",
    tutorials: "Tutorials",
    legal: "Shari'a",
  },

  placeholder_copy: {
    add_account_loader_msg: "Creating accounts, final step",
    group_loader_msg: "Creating group, wait a moment",
    secretary_pay_interest: "Does Secretary pay interest for loans",
    nationality: "Nationality",
    gender: "jinsi",
    female: "mace",
    male: "namiji",
    sms_code: "Verification code",
    login_email: "Lamba Waya...",
    login_password: "kalma-mabuɗi...",
    login_submit: "Shiga hwara aiki",
    login_submitting: "Shiga cikin...",
    signUp_submit: "Tabbatawa",
    signUp_submitting: "Tabbatad da kanki..",
    create_group_name: "Sunan  kungiya...",
    create_group_location: "Mazamnin rkungiya...",
    create_group_submit: "Shigad da sabuwa kungiya",
    create_fund_submit: "Create Fund",
    meeting_date: "Zabi rana-wata-shekara",
    meeting_submit: "Shiriya taro",
    meeting_submitting: "Creating Meeting",
    member_submit: "Sa sabon ɗan kungiya",
    member_name: "Cikakken suna...",
    member_phone: "Lamba waya....",
    number_of_shares: "Jimilla hannayen jari ...",
    shares_submit: "Bada Kudin Zoubi",
    share: "Share",
    loan_amount: "Jimilla basussuka...",
    loan_submit: "Shiriya Bashi",
    welfare_amount: "Jimilla kuɗin taimako...",
    welfare_submit: "Zubin kudin taimako",
    payment_amount: "Adadin zubin kuɗin taimako...",
    repay_submit: "Biỳan Bashi",
    loan_writeoff: "Write-off Loan",
    share_value_amount: "Adadin kuɗin kowane hannu guda ..",
    share_value_submit: "Sabunta adadin kuɗin kowane hannu guda",
    create_group_share_value: "Adadin kuɗi a kowane karshen gewayen rukuni",
    role_in_group: "Zabin ayki cikin rukuni",
    login_pin: "Lamba ka na kanka",
    login_pin_confirm: "Tabbatar da ruhwahwar lambaki na kanki",
    create_group_federation: "Sounan federation...",
    savings_cycle: "Zabi  karshen kewayewa ..",
    country: "Zabi ƙasa",
    date_of_birth: "Zabi shekara aihuwa",
    add_secretary_header: "Add Group Secretary First",
    add_secretary_msg:
      "For a new vsla you need to add the group secretary first, click the button to add.",
    add_secretary_status: "Ƙarawa..",
    add_secretary: "Ƙara magatakarda",
    total_amount: "Jimilla duk kuɗin",
    fine_amount: "Fine amount...",
    fine_submit: "Pay Fine",
    membership_amount: "Membership fee...",
    membership_submit: "Pay Membership",
    sell_shares: "Sell Shares",
    membership_reverse: "Reverse",
    forgot_pin: "Forgot your PIN?",
    account_associated: "Enter the phone number associated with your account",
    token_sent:
      "Enter the 4-digit code you have received through SMS. Resend the code",
    resend_token: "Reset the code",
    submit_smscode: "Enter your new 4-digit PIN code",
    select_nationality: "Select Nationalities",
    next: "Next",
    cashingOut: "Cashing out...",
    finish: "Finish",
    currency: "Select currency",
    select: "Select",
    savings_amount: "Savings amount",
    omt_recipt_number: "OMT Receipt number",
    enter_recipt_number: "Enter receipt number",
    savings_withdrawal: "Savings withdrawal",
    project_name: "Sunan aikin",
    retry: "Sake gwadawa",
    remove: "Cire",
    secretary_creation_msg:
      "A halin yanzu ana ci gaba da samar da sakatariyar...",
    secretary_creation_failed_msg:
      "Ƙirƙirar sakatariya ta kasa. Da fatan za a sake gwadawa.",
  },

  delete: "Shahe Kungiya",
  delete_member: "Shahe ƴar kungiya",
  edit: "Ƙayyade jimilla da sunayen ƴan rukuni",
  save: "Tanadi duk aikin",
  edit_member: "Edit Member",

  alert: {
    upload_error:
      "offline transaction upload encountered an error. Go to offline meeting record to see the error",
    upload_success:
      "Offline transactions have been uploaded and resolved. Check previous meetings records to see it",
    no_members: "Wanga rukuni ba shi da kowa",
    no_savings_cash_out_personal:
      "Savings cash out locked, Uncleared personal loan",
    no_savings_cash_out_group:
      "Savings cash out locked, Uncleared loan in group",
    no_membership_cash_out_personal:
      "Membership cash out locked, Uncleared personal loan",
    no_membership_cash_out_group:
      "Membership cash out locked, Uncleared loan in group",
    cash_out_success: "All conditions have been meet to enable cashing out",
    success: "Success",
    cannot_end_meeting: "Can't end the meeting while a transaction in progress",
    failed: "Failed",
    repay_loans_message: "Repay the loans these members have",
    cannot_cash_out: "Can't cash out while a transaction in progress",
    all_members_cashed_out: "All members have been cashed out",
    no_internet: "No Internet",
    uploading_transactions: "Uploading transactions...",
    member_cashed_out: "Member has been cashed out",
    member_pending_loan: "Member has a loan they need to repay",
    cannot_delete_member_cashout_msg:
      "Can't delete member while cashout in progress",
    cannot_delete_member_secretary_msg: "Can't delete App Secretary",
    cannot_delete_member_has_balance_msg:
      "Can't delete, member needs to be cashed out first",
  },

  dropdowns: {
    secretary: "Magatakarda",
    chairperson: "Shugaba",
    box_keeper: "Mai ajiya asusu",
    keyHolder: "Key Holder",
    money_counter: "Mai ƙilga kuɗi",
    member: "Ƴar kungiya",
    yes: "Na'am",
    no: "A'a",
    nine_months: "9 months",
    six_months: "9 months",
    year: "1 year",
    establishment_date: "Establishment Date",
    business_development: "Business development",
    personal_emergency: "Personal emergency",
    other: "Other",
  },

  cards: {
    total_member: "Total Members",
    cycle_duration: "Cycle Duration",
    country: "Country",
    locality: "Locality",
    federation: "Federation",
    share_value: "Share Value",
    months: "months",
    created_at: "Created on",
    established_on: "Established on",
  },

  notifications: {
    group_exist_error:
      "An riga an ɗauka sunan rukuni. Da fatan za a zaɓi wani daban",
    loan_error: "Error: Member has an active loan",
    edit_success: "Shigad da sabin labaru ya yi",
    edit_error: "(An yi)  Kuskure a shigad da sabin labaru",
    signup_success: "Tabbatarwa ta yi ",
    success: "An gama aikin da kyau",
    error: "Ba a gama aiki ba da kyau",
    group_create_success: "Rukuni ya  azu da kyau",
    group_create_error: "Kuskure a azawan rukuni",
    activation_success: "Rukuni ya hwarhwadu da kyau",
    activation_error: "Kuskure a hwarhwadowa da rukuni",
    meeting_end_success: "Taro ya ƙare",
    meeting_end_error: "Kuskure a ƙarewa taro",
    meeting_create_success: "Taro ya soma",
    meeting_create_error: "Taron bay hwara ba",
    member_create_success: "Azawan ƴar rukuni ta yi",
    member_create_error: "Kuskure a azawan ƴar rukuni",
    no_previous_meeting:
      "Haoussa for There are no previous meetings for this VSLA",
    no_transactions: "Haoussa for There are no transactions for this meeting",
    meeting_no_member_warning: "Ba a hwara taron say an rubuta ỳan VSLA tukuna",
    loan_amount_error: "Basussuka sun zarce izini",
    share_amount_error: "Shares amount exceeds allowed amount",
    share_value_loading: "Samun hannun jari ỳanzu",
    cant_update_shares: "Taron na gudana, ba daman sabunta hannu jari",
    member_phone_error: " Lamba Layi da ke aiki",
    savings_fund_mandatory: "Savings funds is always required",
    fund_create_success: "Fund successfully created",
    gdpr_warning:
      " By proceeding to create the account, you confirm that you are 18 years of age and above",
    can_not_request_welfare:
      "Current welfare balance is zero, request not possible",
    no_reversible_transactions: "No reversible transactions found",
    activate_reversible_transactions:
      "Check the box to activate reversal of transactions",
    welfare_amount_warning: "Amount exceeds allowed amount",
    pin_success: "PIN changed successfully",
    pin_error: "Enter correct code! Try again",
    cancel_txn: "Transaction cancelled",
    processing: "Processing Transaction",
    end_of_cycle_cash_out_warning:
      "Once you've started end of cycle cashout, you won't be able to add any new transaction records until cash out is completed.",
    cash_out_success_msg: "Cash out completed",
    share_value_information:
      "You are starting a new saving cycle. Do you want to change the share value?",
    share_value_updated_msg: "Share value updated!",
    incorrect_username_password_msg:
      "Sunan mai amfani ko kalmar sirri mara daidai",
    contactHiveForSupportMsg:
      "Kuskuren aika lambar Tuntuɓi hiveonline don tallafi",
  },

  badge: {
    meeting_on: "Ƙarewa taron",
  },

  span: {
    current_cycle: "Current Cycle",
    previous_cycles: "Previous cycles",
    transaction_status: "Transactions status",
    login_span: "Ba ki da kontu ?",
    signup: "Tabbata",
    signup_span: "Kina da kontu ?",
    login: "Bude",
    welfare: "sociale",
    savings: "actions",
    fine: "amende",
    endOfCycle: "Fin de cycle",
    midCycle: "Milieu de cycle",
    membership: "Adhésion",
    selectCashOutType: "Select Group Cash Out Type",
    savingsFund: "Savings Fund",
    membershipFund: "Membership Fund",
    welfareFund: "Welfare Fund",
    fineFund: "Fine Fund",
    equally: "Equally",
    fineCashOutType: "Fine Cash Out Type",
    welfareCashOutType: "Welfare Cash Out Type",
    CashOutTypeSelect: "Select Cash Out Type",
    selectFund: "Select Fund",
    member_role: "Member Role",
    processing: "processing",
    back: "Back",
    continue: "Continue",
    no_records_found: "No transactions records found",
    loan_purpose: "Loan purpose",
    cancel: "Cancel",
    save: "Save",
    saving: "Saving...",
    bc_wallet: "BC Walat",
    link: "Mahada",
  },
  banner: {
    install: "Shigar da",
  },
  locale: {
    date: "en-EN",
  },
  status: {
    SUCCESSFUL: "SUCCESSFUL",
    PENDING: "PENDING",
    FAILED: "FAILED",
  },
  locations: {
    country: "Ƙasa",
    county: "Karamar hukuma",
    province: "Lardi",
    region: "Yanki",
    district: "Gundumar",
    gh_district: "Babban birni/Municipal/Lardi",
    municipality: "Municipal",
    sub_county: "Karamar hukuma",
    administrative_post: "Matsayin gudanarwa",
    locality: "Locality",
    select_country: "zabi kasa",
    select_county: "zaɓi gundumomi",
    select_sub_county: "zaɓi yankin yanki",
    select_province: "zaɓi lardin",
    select_region: "zaɓi yanki",
    select_district: "zaɓi gunduma",
    select_municipality: "zaɓi gunduma",
    select_administrative_post: "zaɓi wurin gudanarwa",
    select_locality: "zaɓi adireshin",
    neigbourhood_name: "Unguwa/Sunan titi",
  },
};
