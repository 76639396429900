import React, { Component } from "react";
import {
  Form,
  Input,
  FormGroup,
  Container,
  Alert,
  Label,
} from "reactstrap";
import counterpart from "counterpart";
import Translate from "react-translate-component";
import ZARMA from "../../view/shared/lang/ZARMA";
import ENGLISH from "../../view/shared/lang/ENGLISH";
import SWAHILI from "../../view/shared/lang/SWAHILI";
import HAOUSSA from "../../view/shared/lang/HAOUSSA";
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS";
import SPANISH from "../../view/shared/lang/SPANISH";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import {
  postCreateMemberTwo,
  createMemberError,
} from "../../store/actions/members/createMember";
import uuid from "uuid";
import validateInput from "../../view/shared/validations/addMember";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import AddSecretary from "./AddSecretary";
import "react-responsive-ui/style.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ar from "react-phone-input-2/lang/ar.json";
import LEBANESE from "../../view/shared/lang/LEBANESE";
import MultipleSelect from "../layout/MulitpleSelect";
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE";
import ARABIC from "../../view/shared/lang/ARABIC";
import { defaultDate } from "../../view/shared/utilities";
import { getTranslation } from "../../view/shared/helpers";
import StellarLoader from "../layout/StellarLoader";
counterpart.registerTranslations("ARABIC", ARABIC);

counterpart.registerTranslations("PORTUGUESE", PORTUGUESE);
counterpart.registerTranslations("LEBANESE", LEBANESE);
counterpart.registerTranslations("ZARMA", ZARMA);
counterpart.registerTranslations("SWAHILI", SWAHILI);
counterpart.registerTranslations("ENGLISH", ENGLISH);
counterpart.registerTranslations("HAOUSSA", HAOUSSA);
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS);
counterpart.registerTranslations("SPANISH", SPANISH);

const ErrorMessage = ({ msg }) => {
  if (!!msg) {
    return <div style={failStyle}>{msg}</div>;
  }
  return (
    <Translate
      content="notifications.member_create_error"
      component="div"
      style={failStyle}
    />
  );
};

export class AddMember extends Component {
  notify = (msg) => toast(msg);

  state = {
    name: "",
    phone_number: "",
    member_uuid: uuid.v4(),
    date_of_birth: defaultDate(),
    role_in_group: "",
    paysInterest: "",
    gender: "",
    errors: {},
    userPhone: this.props.user.phone_number.includes("+") ? this.props.user.phone_number: `+${this.props.user.phone_number}`,
    isloading: false,
    nationalities: [],
    lang: localStorage.getItem("lang"),
    _country: localStorage.getItem("_country") || "GH",
  };

  onChange = (e) => {
    const updatedErrors = { ...this.state.errors };
    delete updatedErrors[e.target.name];
    this.setState({ ...this.state, errors: updatedErrors });
    this.setState({ [e.target.name]: e.target.value });
  };

  isValid() {
    const data = { ...this.state };
    delete data.paysInterest;
    const { errors, isValid } = validateInput(
      ["ARABIC", "LEBANESE"].includes(localStorage.getItem("lang"))
        ? data
        : this.state
    );

    if (!isValid) {
      this.setState({ errors });
      return isValid;
    }
    this.setState({ errors: {} });
    return isValid;
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (this.isValid()) {
      this.addMemberBackEndTwo(
        this.state.name,
        this.state.phone_number,
        this.state.member_uuid,
        this.state.date_of_birth,
        this.state.role_in_group,
        this.state.paysInterest,
        this.state.gender,
        this.state.nationalities
      ).then((error) => {
        if (error) {
          return;
        }
        this.setState({
          name: "",
          gender: "",
          phone_number: "",
          date_of_birth: "",
          role_in_group: "",
          paysInterest: "",
        });
      });
    }
  };

  phoneValidate(phone) {
    if (phone === "+") {
      return "";
    } else {
      return phone;
    }
  }

  async addMemberBackEndTwo(
    name,
    phone_number,
    uuid,
    date_of_birth,
    role_in_group,
    paysInterest,
    gender,
    nationalities
  ) {
    const lang = localStorage.getItem("lang");
    const group_id = this.props.vsla[0].id;
    const group_name = this.props.vsla[0].name;

    const notif = {
      ENGLISH: {
        message: `You have been added as a member of ${group_name} savings group`,
      },
      FRANÇAIS: {
        message: `You have been added as a member of ${group_name} savings group`,
      },
      SWAHILI: {
        message: `Umeandikishwa kuwa mwanachama wa kikundi cha akiba ${group_name}`,
      },
      SPANISH: {
        message: `Se le ha agregado como miembro del grupo de ahorro ${group_name}`,
      },
      LEBANESE: {
        message: `You have been added as a household of ${group_name} community`,
      },
      PORTUGUESE: {
        message: `Você foi adicionado como membro do grupo de poupança ${group_name}`,
      },
      ARABIC: {
        message: group_name,
      },
    };

    const finalDate =
      date_of_birth === null ? new Date() : new Date(date_of_birth);
    const dob = moment(finalDate).format("YYYY-MM-DD");

    const member = {
      gender,
      nationalities,
      full_name: name,
      phone: this.phoneValidate("+" + phone_number),
      pubkey: uuid,
      date_of_birth: dob,
      member_role: role_in_group,
      status: "Active",
      loan_service_charge: paysInterest === "Yes" ? true : false,
      msg: Object.values(notif[lang])[0],
    };

    const url = `${window.REACT_APP_PWA_APP_DOMAIN}/api/v1/groups/${group_id}/members?requestId=${uuid}`;
    return this.props
      .createMemberTwo(url, member, {
        Authorization:
          "Bearer " + this.props.user.token || localStorage.getItem("token"),
      })
      .catch(({ error }) => {
        this.notify(<ErrorMessage msg={error} />);
        return Promise.reject(error);
      });
  }

  getPhoneNumbers = () => {
    const { members } = this.props;
    let phoneArray = [];
    members.forEach((member) => {
      if (member.blockchain_wallet_id) {
        phoneArray.push(this.phoneValidate(member.phone));
      }
    });
    return phoneArray.filter((n) => n); //remove empty strings from phoneArray
  };

  render() {
    counterpart.setLocale(localStorage.getItem("lang"));
    const yes = counterpart.translate("dropdowns.yes");
    const no = counterpart.translate("dropdowns.no");
    const paysInterest = counterpart.translate(
      "placeholder_copy.pays_interest"
    );
    const member_submit = counterpart.translate(
      "placeholder_copy.member_submit"
    );
    const member_phone = counterpart.translate("placeholder_copy.member_phone");
    const member_name = counterpart.translate("placeholder_copy.member_name");
    const birthdate = counterpart.translate("placeholder_copy.date_of_birth");
    const role_in_group = counterpart.translate(
      "placeholder_copy.role_in_group"
    );

    const secretary = counterpart.translate("dropdowns.secretary");
    const chairperson = counterpart.translate("dropdowns.chairperson");
    const box_keeper = counterpart.translate("dropdowns.box_keeper");
    const keyHolder = counterpart.translate("dropdowns.keyHolder");
    const money_counter = counterpart.translate("dropdowns.money_counter");
    const member = counterpart.translate("dropdowns.member");
    const female = counterpart.translate("placeholder_copy.female");
    const male = counterpart.translate("placeholder_copy.male");
    const gender = counterpart.translate("placeholder_copy.gender");
    const nationality = counterpart.translate("placeholder_copy.nationality");
    const select_nationality = counterpart.translate(
      "placeholder_copy.select_nationality"
    );

    const { errors, lang, _country } = this.state,
      en = false;
    const phoneArray = this.getPhoneNumbers();

    const headerStyle = {
      color: "#E36F1E",
      fontFamily: "quicksand",
      fontWeight: "bolder",
      fontSize: "1.8em",
      textAlign: "center",
      paddingBottom: "0.5em",
    };

    const formStyle = {
      flex: "10",
      padding: "5px",
      fontFamily: "Fira Sans",
      fontSize: "17px",
      height: "50px",
    };

    const errorStyle = {
      fontFamily: "Fira Sans",
      fontSize: "17px",
      color: "red",
    };

    if (this.props.isCreating || this.state.isloading) {
      return <StellarLoader />;
    }

    if (!phoneArray.includes(this.state.userPhone) && !this.state.isloading) {
      return (
        <div>
          <AddSecretary />
        </div>
      );
    }

    return (
      <div>
        {this.props.error && <ErrorMessage msg={this.props.error} />}
        <div
          style={{
            backgroundColor: "#white",
            padding: ".5rem",
            textAlign: "center",
            margin: "34px 0 0 0",
          }}
        >
          <Translate
            content="title_copy.add_member"
            component="h4"
            style={headerStyle}
          />
        </div>
        <Form onSubmit={this.onSubmit} noValidate>
          <Container>
            <FormGroup>
              <Label style={loadingStyle}>{member_name}</Label>
              <Input
                type="text"
                name="name"
                style={formStyle}
                placeholder={member_name}
                onChange={this.onChange}
                value={this.state.name}
              />
              <span style={errorStyle}>{errors.name}</span>
            </FormGroup>

            <FormGroup
              style={{
                direction: "ltr",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Label
                style={{
                  ...loadingStyle,
                  alignSelf:
                    localStorage.getItem("lang") === "ARABIC"
                      ? "end"
                      : "initial",
                }}
              >
                {member_phone}
              </Label>
              <PhoneInput
                country={_country.toLocaleLowerCase()}
                placeholder={member_phone}
                error={errors.phone_number}
                enableSearch
                disableSearchIcon
                style={loadingStyle}
                inputStyle={{
                  flex: "10",
                  fontFamily: "Fira Sans",
                  height: "50px",
                  width: "100%",
                  fontSize: "20px",
                }}
                value={this.state.phone}
                onChange={(phone_number) => this.setState({ phone_number })}
                limitMaxLength={true}
                localization={lang === "ARABIC" ? ar : en}
              />
              <span style={errorStyle}>{errors.phone_number}</span>
              {phoneArray.includes(this.state.phone_number) ? (
                <span style={errorStyle}>
                  <Translate content="notifications.member_phone_error" />
                </span>
              ) : (
                <span></span>
              )}
            </FormGroup>

            <FormGroup
              style={{
                direction: "ltr",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Label
                style={{
                  ...loadingStyle,
                  alignSelf:
                    localStorage.getItem("lang") === "ARABIC"
                      ? "end"
                      : "initial",
                }}
              >
                {birthdate}
              </Label>
              <Input
                type="date"
                name="date_of_birth"
                placeholder={birthdate}
                selected={this.state.date_of_birth}
                style={formStyle}
                onChange={this.onChange}
                defaultValue={defaultDate()}
                numberofmonths={1}
                max={defaultDate()}
              />
              <span style={errorStyle}>{errors.date_of_birth}</span>
            </FormGroup>
            {["ARABIC", "LEBANESE"].includes(localStorage.getItem("lang")) && (
              <MultipleSelect
                title={nationality}
                preSetItems={this.state.nationalities}
                callback={(items) => {
                  this.setState({ ...this.state, nationalities: items });
                }}
                placeholderOption={select_nationality}
                options={[
                  {
                    value: getTranslation("countries", "Lebanon"),
                    title: getTranslation("countries", "Lebanon"),
                  },
                  {
                    value: getTranslation("countries", "Syria"),
                    title: getTranslation("countries", "Syria"),
                  },
                  {
                    value: getTranslation("countries", "Palestine"),
                    title: getTranslation("countries", "Palestine"),
                  },
                ]}
              />
            )}
            <FormGroup>
              <Label
                style={{
                  ...loadingStyle,
                  textAlign:
                    localStorage.getItem("lang") === "ARABIC"
                      ? "right"
                      : "initial",
                  width:
                    localStorage.getItem("lang") === "ARABIC"
                      ? "100%"
                      : "initial",
                }}
              >
                {gender}
              </Label>
              <Input
                id="gender"
                type="select"
                name="gender"
                style={formStyle}
                onChange={this.onChange}
                value={this.state.gender}
              >
                <option disabled value="">
                  {gender}
                </option>
                <option value="female">{female}</option>
                <option value="male">{male}</option>
              </Input>
              <span style={errorStyle}>{errors.gender}</span>
            </FormGroup>
            <FormGroup>
              <Label
                style={{
                  ...loadingStyle,
                  textAlign:
                    localStorage.getItem("lang") === "ARABIC"
                      ? "right"
                      : "initial",
                  width:
                    localStorage.getItem("lang") === "ARABIC"
                      ? "100%"
                      : "initial",
                }}
              >
                {role_in_group}
              </Label>
              <Input
                id="select_role"
                type="select"
                name="role_in_group"
                style={formStyle}
                onChange={this.onChange}
                value={this.state.role_in_group}
              >
                <option value="">{role_in_group}</option>
                <option>{chairperson}</option>
                {!["ARABIC", "LEBANESE"].includes(
                  localStorage.getItem("lang")
                ) && (
                  <>
                    <option>{secretary}</option>
                    <option>{box_keeper}</option>
                    <option>{money_counter}</option>
                    <option>{keyHolder}</option>
                  </>
                )}
                <option>{member}</option>
              </Input>
              <span style={errorStyle}>{errors.role_in_group}</span>
            </FormGroup>
            {!["ARABIC", "LEBANESE"].includes(localStorage.getItem("lang")) && (
              <FormGroup>
                <Label style={loadingStyle}>{paysInterest}</Label>
                <Input
                  id="paysInterest"
                  type="select"
                  name="paysInterest"
                  style={formStyle}
                  onChange={this.onChange}
                  value={this.state.paysInterest}
                >
                  <option value="">{paysInterest}</option>
                  <option value="Yes">{yes}</option>
                  <option value="No">{no}</option>
                </Input>
                <span style={errorStyle}>{errors.paysInterest}</span>
              </FormGroup>
            )}
            <Alert color="warning">
              <span>
                <Translate
                  content="notifications.gdpr_warning"
                  style={{ fontSize: "16px", fontFamily: "Fira Sans" }}
                />
              </span>
            </Alert>
          </Container>
          <Container>
            <FormGroup>
              <Input
                type="submit"
                value={member_submit}
                name="submit"
                className="btn btn-primary"
                disabled={
                  phoneArray.includes(this.state.phone_number) ? true : false
                }
              />
            </FormGroup>
          </Container>
        </Form>
      </div>
    );
  }
}

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
};

const failStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
  color: "red",
  fontWeight: "bold",
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    vsla: state.loadedvslas,
    members: state.loadedvslaMembers,
    isCreating: state.isCreatingVslaMember,
    error: state.createMemberError,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createMemberError: (bool) => dispatch(createMemberError(bool)),
    createMemberTwo: (url, member, headers) =>
      dispatch(postCreateMemberTwo(url, member, headers, ownProps)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMember);
