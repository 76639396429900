export default {
  countries: {
    Niger: "Niger",
    Tanzania: "Tanzania",
    Rwanda: "Rwanda",
    Zambia: "Zambia",
    Honduras: "Honduras",
    Nigeria: "Nigeria",
    Lebanon: "Lebanon",
    Mozambique: "Moçambique",
    Moçambique: "Moçambique",
    Ghana: "Ghana",
    Syria: "Syria",
    Palestine: "Palestine",
  },
  title_copy: {
    status: "status",
    version_msg: "You're using the test version of vsla.online",
    cycles_title: "Saving cycles",
    header_login: "FEERI",
    header_signup: "AKIIKANDI",
    create_group: "SATA TAJI SIJIRI",
    group_finance: "Gaakasinay:",
    group_header: "SATEY",
    loading: "Albaharu danyaŋ...",
    updating: "Tajanda nda  furo-goy...",
    check_status: "Sata alhaalo guna a ma boori",
    no_vsla: "Sata si no",
    create_meeting: "MARGA SIJIRI",
    meeting_members: "Sata borey",
    meeting: "MARGA",
    prev_meeting: "BANDA MARGEY",
    offline_meeting: "Offline meeting",
    add_member: "BORO BOŊ LAABAREY",
    buy_shares: "KAMBE ZOBO",
    sell_shares: "SELL SHARES",
    fine: "PAY FINE",
    welfare: "GAAKASINAY NOORU NOOYAŊ",
    welfare_request: "WELFARE REQUEST",
    membership_fee: "MEMBERSHIP FEE",
    loan: "GARAW",
    funds: "NOORU BORMO",
    more_details: "Tontoni baaruizeỳanŋ",
    vsla_actions: "Sata Goyey",
    mem_actions: "Sata-ize Goyey",
    memberlist_overview: " MAAYEY SASARA",
    mem_menu: "TONTON SATA-IZEYAŊ (MA)",
    loan_repayment: "GARAW BANAYAŊ",
    loan_writeoff: "WRITE OFF LOAN",
    outstanding_loan: "Garaw Kaŋ Deeji:",
    attendees: "Marga do Borey",
    share_value: "Kambe Fo Nooro Dimma",
    settings: "SOOLA-SOOLEY",
    current_savings: "Nooru lambante ",
    cycle_duration: "Kuuyaŋo",
    addFundAccount: "ADD FUND ACCOUNT",
    supported_accounts: "Supported accounts",
    lastTransaction: "Last transaction",
    cashOut: "Retirer",
    warning: "Warning",
    end_of_cycle_cash_out: "End-cycle cash out",
    reminder: "Reminder",
  },

  menu: {
    vlsa: "KWAARA",
    group: "Sata",
    meeting: "Marga",
    create_vsla: "Sata (taji) sijiriyaŋ",
    vsla_overview: "Sata boŋ baaruyaŋ",
    new_meeting: "Marga Taji",
    prev_meeting: "Banda Margey",
    offline_meeting: "Offline meeting",
    vsla_saving: "Nooru Gisante Bormo:",
    vlsa_loan: "Garawey Dimma:",
    vlsa_balance: "Sohon Nooro Kaŋ Cindi:",
    funds: "Nooru fafara",
    members: "Sata-izey",
    members_overview: "Sata-izey boŋ albahaaruyaŋ",
    add_member: "Sata-ize (taji) ma tonton",
    end_meeting: "Marga daabu",
    current_meeting: "Marga Kaŋ Go Ga Te",
    settings: "Soola-sooley",
    options: "Suubanyaŋey",
    language: "Language",
    close: " Goyo Kulu Gaayi    ",
    savingsFund: "Savings fund",
    welfareFund: "Welfare Fund",
    loanInterestFund: "Loan Interest Fund",
    fineFund: "Fine Fund",
    membershipFund: "Membership Fund",
    end_of_cycle_cash_out: "End of cycle cash out",
  },

  menu_mem: {
    overview: "Sata-izey boŋ albahaaruyaŋ",
    welfare: "Gaakasinay  nooru daŋyaŋ",
    welfare_request: "Welfare Request",
    buy_shares: "Kambe furoyaŋey",
    create_loan: "Garaw sijiriyaŋ",
    repay_loan: "Garaw Banayaŋ",
    writeoff_loan: "Write-off Loan",
    pay_fine: "Pay fine",
    membership_fee: "Membership Fee",
    sell_shares: "Sell Shares",
    transaction_amount: "Transaction Amount",
    group_income: "Group Income",
    group_expense: "Group Expenses",
    fine_payment: "Fine Payment",
    welfare_contribution: "Welfare Contribution",
    loan_repayment: "LOAN REPAYMENT",
    loan_interest_repayment: "Loan Interest Repayment",
    fine_payment_reversal: "Fine Payment Reversed",
    buy_shares_reversal: "Buy Shares Reversed",
    welfare_contribution_reversal: "Welfare Contribution Reversed",
    sell_shares_reversal: "Sell Shares Reversed",
    loan_interest_repayment_reversal: "Loan Interest Repayment Reversed",
    welfare_request_reversal: "Welfare Request Reversed",
    membership_fee_reversal: "Membership Fee Reversed",
    savings_cash_out: "Savings cashout",
    midCycle_Cashout: "Mid-cycle cash out",
    group_income_fund: "Group income - Savings",
    group_income_fine: "Group income - Fine",
    group_income_welfare: "Group income - Welfare",
    group_expense_fund: "Group expense - Savings",
    group_expense_fine: "Group expense - Fine",
    group_expense_welfare: "Group expense - Welfare",
  },

  mem_overview: {
    welfare: "Gaakasinay Nooru daŋyaŋ:",
    shares_in_francs: "Kambey kulu nooro dimma:",
    loan: "Garawey Dimma:",
    shares_in_numbers: "Kambey baayaŋo kabo:",
    welfare_total: "Welfare:",
    shares_total: "Shares:",
  },

  header: {
    home: "Nangoray",
    vsla: "Sata",
    logout: "Fatta ",
    whats_new: "What's New",
    tutorials: "Tutorials",
    legal: "Legal",
  },

  placeholder_copy: {
    secretary_pay_interest: "Does Secretary pay interest for loans",
    nationality: "Nationality",
    gender: "Gender",
    female: "Female",
    male: "Male",
    sms_code: "Verification code",
    login_email: "Sisiri lamba...",
    login_password: "Ni (Furoyaŋ) Tuguray-saafi",
    login_submit: "Furo ga goy",
    login_submitting: "Furora...",
    signUp_submit: "Akiikandi",
    signUp_submitting: "Boŋ akiikandiyaŋ...",
    create_group_name: "Sata maayo...",
    create_group_location: "Sata nongora do...",
    create_group_submit: "Sata sijiri",
    create_fund_submit: "Create Fund",
    meeting_date: "Suuban hano",
    meeting_submit: "Marga sijiri",
    meeting_submitting: "Creating Meeting",
    member_submit: "Sata-ize sijiri",
    member_name: "Ma kubante...",
    member_phone: "Sisiri lamba...",
    number_of_shares: "Adaase Kambey dimma",
    shares_submit: "Adaase Kambe furoỳaney",
    share: "Adaase kambe",
    loan_amount: "Garawey dimma",
    loan_submit: "Garaw sijiri",
    welfare_amount: "Gaakasinay nooro dimma",
    welfare_submit: "Gaakasinay nooru daŋyaŋ",
    payment_amount: "Gaakasinay nooro dimma",
    repay_submit: "Garaaw Banayaŋ",
    loan_writeoff: "Write-off Loan",
    share_value_amount: "Kambe fo kulu nooro dimma",
    share_value_submit: "Kambe fo kulu dimma tajanda",
    create_group_share_value: "Sata windiyaŋ fo kulu nooro dimma",
    role_in_group: "Suuban sata ra goy",
    login_pin: "Boro (bumbo hinne) lamba daabanta",
    login_pin_confirm: "Boro bumbo hinne lamba akiikandi",
    create_group_federation: "Federasiyo Mayi...",
    savings_cycle: "Suuban gisiri sijira windiyaŋo kuuyaŋo",
    country: "Suuban laabu",
    date_of_birth: "Suuban hayyaŋ jiire",
    add_secretary_header: "Sata magatakarda maayo daŋ sintinay",
    add_secretary_msg: "Nda MMD taji no",
    add_secretary_status: "Tontonyaŋ...",
    add_secretary: "Magatakarda Tonton",
    total_amount: "Nooro kulu dimma",
    fine_amount: "Fine amount...",
    fine_submit: "Pay Fine",
    membership_amount: "Membership fee...",
    membership_submit: "Pay Membership",
    sell_shares: "Sell Shares",
    membership_reverse: "Reverse",
    forgot_pin: "Forgot your PIN?",
    account_associated: "Enter the phone number associated with your account",
    token_sent:
      "Enter the 4-digit code you have received through SMS. Resend the code",
    resend_token: "Reset the code",
    submit_smscode: "Enter your new 4-digit PIN code",
    select_nationality: "Select Nationalities",
    next: "Next",
    cashingOut: "Cashing out...",
    finish: "Finish",
    currency: "Select currency",
    select: "Select",
    savings_amount: "Savings amount",
    omt_recipt_number: "OMT Receipt number",
    enter_recipt_number: "Enter receipt number",
    savings_withdrawal: "Savings withdrawal",
    project_name: "Project Name",
    retry: "Retry",
    remove: "Remove",
    secretary_creation_msg: "Secretary creation is currently in progress...",
    secretary_creation_failed_msg: "Secretary creation failed. Please retry.",
  },

  delete: "Sata tuusu",
  delete_member: "Sata-ize tuusu",
  edit: "Sata-izey dimma da maayey kaỳandi",
  save: "Goyo hallasi",
  edit_member: "Edit Member",

  alert: {
    upload_error:
      "offline transaction upload encountered an error. Go to offline meeting record to see the error",
    upload_success:
      "Offline transactions have been uploaded and resolved. Check previous meetings records to see it",
    no_members: "Sato sinda boro kulu",
    no_savings_cash_out_personal:
      "Savings cash out locked, Uncleared personal loan",
    no_savings_cash_out_group:
      "Savings cash out locked, Uncleared loan in group",
    no_membership_cash_out_personal:
      "Membership cash out locked, Uncleared personal loan",
    no_membership_cash_out_group:
      "Membership cash out locked, Uncleared loan in group",
    cash_out_success: "All conditions have been meet to enable cashing out",
    success: "Success",
    cannot_end_meeting: "Can't end the meeting while a transaction in progress",
    failed: "Failed",
    repay_loans_message: "Repay the loans these members have",
    cannot_cash_out: "Can't cash out while a transaction in progress",
    all_members_cashed_out: "All members have been cashed out",
    no_internet: "No Internet",
    uploading_transactions: "Uploading transactions...",
    member_cashed_out: "Member has been cashed out",
    member_pending_loan: "Member has a loan they need to repay",
    cannot_delete_member_cashout_msg:
      "Can't delete member while cashout in progress",
    cannot_delete_member_secretary_msg: "Can't delete the App Secretary",
    cannot_delete_member_has_balance_msg:
      "Can't delete, member needs to be cashed out first",
  },

  dropdowns: {
    secretary: "Magatakarda",
    chairperson: "Jine-fune",
    box_keeper: "Asuuso gisika",
    keyHolder: "Key Holder",
    money_counter: "Nooru kabuka",
    member: "Sata-ize",
    yes: "Oho",
    no: "Hã'ã",
    nine_months: "9 months",
    six_months: "6 months",
    year: "1 year",
    establishment_date: "Establishment Date",
    business_development: "Business development",
    personal_emergency: "Personal emergency",
    other: "Other",
  },

  cards: {
    total_member: "Total Members",
    cycle_duration: "Kuuyaŋo",
    country: "Country",
    locality: "Locality",
    federation: "Federasiyo Mayi",
    share_value: "Kambe Fo Nooro Dimma",
    months: "months",
    created_at: "Created on",
    established_on: "Established on",
  },

  notifications: {
    group_exist_error:
      "Group name already taken. Please choose a different one",
    loan_error: "Error: Member has an active loan",
    edit_success: "Albahaaru daŋyaŋo te",
    edit_error: "Darayyaŋ te albaharu daŋyaŋo ga",
    signup_success: "Akiikandiyaŋo te",
    success: "Goyo kulu te",
    error: "Darayyaŋ te goyo ra",
    group_create_success: "Sata sijiriyaŋo boori",
    group_create_error: "Darayyaŋ te sata sijiriyaŋo ra ",
    activation_success: "Sata furo-goy a boori",
    activation_error: "Darayyaŋ te sata furo-goyyaŋo ra",
    meeting_end_success: "Marga ban",
    meeting_end_error: "Darayyaŋ te marga banyaŋo ra",
    meeting_create_success: "Marga sintin",
    meeting_create_error: "Marga mana sintin",
    member_create_success: "Sata-ize daŋyaŋo te a boori",
    member_create_error: "Darayyaŋ te sata-ize daŋyaŋo ra",
    no_previous_meeting:
      "Zarma for There are no previous meetings for this VSLA",
    no_transactions: "Zarma for There are no transactions for this meeting",
    meeting_no_member_warning:
      "Marga si du ga sintin da matti i na VSLA izey tonton no jina",
    loan_amount_error: "Garawey na hirro daaru",
    share_amount_error: "Shares amount exceeds allowed amount",
    share_value_loading: "Sohoŋ kambe dimma duyaŋo",
    cant_update_shares:
      "Marga go ga te kambe dimma si du ga tajanda A na ta taron, ba aiya",
    member_phone_error: " Dimma sifa kaŋ go ga goy",
    savings_fund_mandatory: "Deselect unused funds. Savings funds is required",
    fund_create_success: "Fund successfully created",
    gdpr_warning:
      "By proceeding to create the account, you confirm that you are 18 years of age and above",
    can_not_request_welfare:
      "Current welfare balance is zero, request not possible",
    no_reversible_transactions: "No reversible transactions found",
    activate_reversible_transactions:
      "Check the box to activate reversal of transactions",
    welfare_amount_warning: "Amount exceeds allowed amount",
    pin_success: "PIN changed successfully",
    pin_error: "Enter correct code! Try again",
    cancel_txn: "Transaction cancelled",
    processing: "Processing Transaction",
    end_of_cycle_cash_out_warning:
      "Once you've started end of cycle cashout, you won't be able to add any new transaction records until cash out is completed.",
    cash_out_success_msg: "Cash out completed",
    share_value_updated_msg: "Share value updated!",
    contactHiveForSupportMsg:
      "Error sending code. Contact hiveonline for support",
  },

  badge: {
    meeting_on: "Marga banyaŋo",
  },

  span: {
    current_cycle: "Current Cycle",
    previous_cycles: "Previous cycles",
    transaction_status: "Transactions status",
    login_span: "Ni sinda kontu ?",
    signup: "Akiikandi",
    signup_span: "Ni gonda kontu ?",
    login: "Feeri",
    welfare: "sociale",
    savings: "actions",
    fine: "amende",
    endOfCycle: "Fin de cycle",
    midCycle: "Milieu de cycle",
    membership: "Adhésion",
    selectCashOutType: "Select Group Cash Out Type",
    savingsFund: "Savings Fund",
    membershipFund: "Membership Fund",
    welfareFund: "Welfare Fund",
    fineFund: "Fine Fund",
    equally: "Equally",
    fineCashOutType: "Fine Cash Out Type",
    welfareCashOutType: "Welfare Cash Out Type",
    CashOutTypeSelect: "Select Cash Out Type",
    selectFund: "Select Fund",
    loanServiceCharge: "Loan Service Charge",
    member_role: "Member Role",
    processing: "processing",
    back: "Back",
    continue: "Continue",
    no_records_found: "No transactions records found",
    loan_purpose: "Loan purpose",
    cancel: "Cancel",
    save: "Save",
    saving: "Saving...",
    bc_wallet: "BC Wallet",
    link: "Link",
  },
  banner: {
    install: "Install",
  },
  locale: {
    date: "en-EN",
  },
  status: {
    SUCCESSFUL: "SUCCESSFUL",
    PENDING: "PENDING",
    FAILED: "FAILED",
  },
  locations: {
    country: "Country",
    county: "County",
    province: "Province",
    region: "Region",
    district: "District",
    gh_district: "Metropolitan/Municipal/District",
    municipality: "Municipality",
    sub_county: "Sub-County",
    administrative_post: "Administrative post",
    locality: "Locality",
    select_country: "select country",
    select_county: "select county",
    select_sub_county: "select sub-county",
    select_province: "select province",
    select_region: "select region",
    select_district: "select district",
    select_municipality: "select municipality",
    select_administrative_post: "select administrative post",
    select_locality: "select address",
    neigbourhood_name: "Neighborhood/Street name",
  },
};
