import React from "react";
import { Button, ListGroup, ListGroupItem, Container, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Translate from "react-translate-component";
import { connect } from "react-redux";
import { itemsFetchBalances } from "../../store/actions/getVslaBalance";
import { memberLoaded } from "../../store/actions/members/getMember";
import { fetchTransactionsAggreg } from "../../store/actions/getMemberAggregates";
import { selectVlsaFundAccounts } from "../../store/reducers/getVslas";
import { patchMemberHOL } from "../../store/actions/updateMemberInfo";
import { toast } from "react-toastify";
import { getTranslation } from "../../view/shared/helpers";
import { selectMemberReversedLoanPaidTrasactions } from "../../store/reducers/getTransactions";
import counterpart from "counterpart";
import { getMemberBalanceByMemberId } from "../../store/reducers/transactions/actions";
import MultipleSelect from "../layout/MulitpleSelect";
import PhoneInput from "react-phone-input-2";
import ar from "react-phone-input-2/lang/ar.json";
import "react-phone-input-2/lib/style.css";
import { defaultDate } from "../../view/shared/utilities";
import { Label } from "reactstrap";
import { fetchVslasMembers } from "../../store/actions/members/getVslaMembers";
import { setCurrentPage } from "../../store/reducers/navigation";
import { deleteMemberAction } from "../../store/actions/members/deleteMember";
import StellarLoader from "../layout/StellarLoader";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../view/shared/utilities/cache";
import CustomModal from "../layout/Modal";

const iconStyle = {
  fontSize: "20px",
  float: "right",
  transform:
    localStorage.getItem("lang") === "ARABIC" ? "rotateY(180deg)" : "none",
};
const linkReverseStyles = {
  display: "flex",
  justifyContent: "space-between",
};
const headerStyle = {
  color: "#E36F1E",
  fontFamily: "Montserrat",
  fontWeight: "bolder",
  fontSize: "1.5rem",
  textAlign: "center",
  paddingBottom: "0.5em",
};

const actionStyle = {
  backgroundColor: "white",
  padding: ".3rem",
  textAlign: "center",
  fontFamily: "Fira Sans",
  fontSize: "20px",
  color: "#696969",
  fontStyle: "italic",
};

const formStyle = {
  flex: "10",
  padding: "5px",
  fontFamily: "Fira Sans",
  fontSize: "17px",
  height: "50px",
};

const inputStyle = {
  flex: "10",
  margin: "8px 0",
  padding: "5px",
  fontFamily: "Fira Sans",
  fontSize: "20px",
};

let totalSHA = [0];
let totalSC = [0];

const SuccessMessage = () => {
  return (
    <Translate
      content="notifications.edit_success"
      component="div"
      style={loadingStyle}
    />
  );
};

const ErrorMessage = () => {
  return (
    <Translate
      content="notifications.edit_error"
      component="div"
      style={loadingStyle}
    />
  );
};

const notify = (msg) => toast.info(msg);

const Actions = ({
  isLoading,
  user,
  vsla,
  setPage,
  meetingTransactions,
  isConnected,
  fetchMembers,
  fund_balance,
  currentMember,
  loadedvslaMembers,
  membersBalance,
  getAggregates,
  ongoingMeeting,
  patchMemberhol,
  availableAccounts,
  getMemberBalance,
  processingCashout,
  getUpdatedVslaBalance,
  reversedLoanRepaidTransaction,
  cashoutStarted,
  deleteUser,
  updateMemberInfo,
}) => {
  const [params, setParams] = React.useState({
    mem_id: currentMember.blockchain_wallet_id,
    vsla_id: vsla.id,
    name: currentMember.full_name,
  });
  const [isOpen, setIsOpen] = React.useState(false);
  const [modalInputData, setInputData] = React.useState();

  const [account, setAccount] = React.useState({
    loan: {
      assetType: "SC",
      balance: 0,
      accountType: "loan",
    },
    total_loan_interest: {
      assetType: "SC",
      balance: 0,
      accountType: "total_loan_interest",
    },
    paid_loan_interest: {
      assetType: "SC",
      balance: 0,
      accountType: "paid_loan_interest",
    },
    loan_interest: {
      assetType: "SC",
      balance: 0,
      accountType: "loan_interest",
    },
    welfare: {
      assetType: "SC",
      balance: 0,
      accountType: "welfare",
    },
    welfare_requests: {
      assetType: "SC",
      balance: 0,
      accountType: "welfare_requests",
    },
    savings: {
      assetType: "SC",
      balance: 0,
      accountType: "savings",
    },
    shares: {
      assetType: "SC",
      balance: 0,
      accountType: "shares",
    },
    "Fine paid": {
      assetType: "SC",
      balance: 0,
      accountType: "Fine paid",
    },
    membership: {
      assetType: "SC",
      balance: 0,
      accountType: "membership",
    },
  });

  React.useEffect(() => {
    const data = membersBalance[currentMember.blockchain_wallet_id];
    if (!currentMember || !data) {
      return;
    }
    setAccount(data);
  }, []);

  React.useEffect(() => {
    const data = membersBalance[currentMember.blockchain_wallet_id];
    if (!currentMember || !data) {
      return;
    }
    setAccount(data);
  }, [currentMember]);

  const cachedTransactions = useLiveQuery(() => {
    try {
      return db.pendingTransactions
        .filter(
          ({ data, error, vslaId }) =>
            vslaId === vsla.id && data.memberId === params.mem_id && !error
        )
        .toArray();
    } catch (error) {
      console.log(`Error: ${error}`);
      return [];
    }
  }, []);

  const [isCreatingLoan, setCreatingLoan] = React.useState(false);
  const [isPayingFee, setServiceFeePayment] = React.useState(false);
  const [isPayingLoan, setLoanPayment] = React.useState(false);
  const [isRequestingWellFare, setRequestingWellFare] = React.useState(false);
  const [isSellingShares, setSellingShares] = React.useState(false);
  const [isWritingOffLoan, setWritingOffLoan] = React.useState(false);

  React.useEffect(() => {
    if (!Array.isArray(cachedTransactions)) {
      return;
    }
    const ts = [
      ...cachedTransactions.map(({ data }) => data),
      ...meetingTransactions,
    ];
    let CR = [];
    let LIR = [];
    let LR = [];
    let WFQ = [];
    let WROff = [];
    let SSH = [];
    ts.forEach((item) => {
      const type = item.data ? item.data.type : item.transactionType;
      if (type === "CREATE_LOAN" && item.status !== "SUCCESSFUL") {
        CR.push(type);
      } else if (
        type === "LOAN_INTEREST_REPAYMENT" &&
        item.status !== "SUCCESSFUL"
      ) {
        LIR.push(type);
      } else if (type === "LOAN_REPAYMENT" && item.status !== "SUCCESSFUL") {
        LR.push(type);
      } else if (type === "WELFARE_REQUEST" && item.status !== "SUCCESSFUL") {
        WFQ.push(type);
      } else if (type === "WRITE_OFF_LOAN" && item.status !== "SUCCESSFUL") {
        WROff.push(type);
      } else if (type === "SELL_SHARES" && item.status !== "SUCCESSFUL") {
        SSH.push(type);
      }
    });

    setCreatingLoan(CR.length > 0);
    setServiceFeePayment(LIR.length > 0);
    setLoanPayment(LR.length > 0);
    setRequestingWellFare(WFQ.length > 0);
    setSellingShares(SSH.length > 0);
    setWritingOffLoan(WROff.length > 0);
  }, [cachedTransactions, meetingTransactions]);

  const secretary = counterpart.translate("dropdowns.secretary");
  const chairperson = counterpart.translate("dropdowns.chairperson");
  const box_keeper = counterpart.translate("dropdowns.box_keeper");
  const money_counter = counterpart.translate("dropdowns.money_counter");
  const member = counterpart.translate("dropdowns.member");
  const female = counterpart.translate("placeholder_copy.female");
  const male = counterpart.translate("placeholder_copy.male");
  const gender = counterpart.translate("placeholder_copy.gender");
  const nationality = counterpart.translate("placeholder_copy.nationality");
  const select_nationality = counterpart.translate(
    "placeholder_copy.select_nationality"
  );
  const member_phone = counterpart.translate("placeholder_copy.member_phone");
  const member_name = counterpart.translate("placeholder_copy.member_name");
  const keyHolder = counterpart.translate("dropdowns.keyHolder");

  const [isEditing, setIsEditing] = React.useState(false);
  const [state, setState] = React.useState({
    pubkey: currentMember.pubkey,
    phone: currentMember.phone,
    full_name: currentMember.full_name,
    date_of_birth: currentMember.date_of_birth,
    member_role: currentMember.member_role,
    gender: currentMember.gender,
    nationalities: currentMember.nationalities
      ? currentMember.nationalities
      : [],
  });

  const [editButtonDisable] = React.useState(false);

  const fetchData = async () => {
    if (!isConnected) {
      return;
    }
    const headers = {
      headers: {
        Authorization: "Bearer " + user.token || localStorage.getItem("token"),
      },
    };

    const headersBC = {
      headers: {
        Authorization:
          "Bearer " + user.tokenBC || localStorage.getItem("tokenBC"),
      },
    };

    Promise.all([
      getUpdatedVslaBalance(`/vsla/${params.vsla_id}/balance`, headersBC),
      getMemberBalance(params.mem_id),
    ]);
    getAggregates(headers);
  };

  React.useEffect(() => {
    fetchData();
    fund_balance.forEach((f) => {
      if (f.asset === "SC") {
        totalSC.push(parseFloat(f.balance));
      }

      if (f.asset === "SHAR") {
        totalSHA.push(parseFloat(f.balance));
      }
    });
  }, []);

  React.useEffect(() => {
    setState({
      pubkey: currentMember.pubkey,
      phone: currentMember.phone,
      full_name: currentMember.full_name,
      date_of_birth: currentMember.date_of_birth,
      member_role: currentMember.member_role,
      gender: currentMember.gender,
      nationalities: currentMember.nationalities
        ? currentMember.nationalities
        : [],
    });
    if (!currentMember.full_name) {
      return;
    }
    setParams({ ...params, name: currentMember.full_name });
  }, [currentMember]);

  const balanceCheck = (params) => {
    return params.every((element) => element.balance === 0);
  };

  const deactivateDeleteBtn = () => {
    //if member can be deleted, enable delete button
    if (eligibleForDeletion()) {
      return false;
    }
    return true;
  };

  const eligibleForDeletion = () => {
    const balances = Object.values(account);
    //if member has balances, member cannot be deleted
    if (!balanceCheck(balances)) {
      return false;
    }
    return true;
  };

  const deleteMember = async () => {
    if (window.confirm(getTranslation("memberActions", "confirm_message"))) {
      const member = loadedvslaMembers.find(
        (member) => member.pubkey === currentMember.pubkey
      );

      //check if cashout in progress
      if (
        !["ARABIC", "LEBANESE"].includes(localStorage.getItem("lang")) &&
        cashoutStarted
      ) {
        alert(getTranslation("alert", "cannot_delete_member_cashout_msg"));
        return;
      }

      //check if App secretary
      if (member.member_role === "Secretary") {
        alert(getTranslation("alert", "cannot_delete_member_secretary_msg"));
        return;
      }

      //delete member
      const data = {
        pubkey: member.pubkey,
        memberId: params.mem_id,
      };
      await deleteUser(member, data, member.id);
    }
  };

  const onChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  const toggleEditMember = () => setIsEditing(!isEditing);

  const updateMember = () => {
    const headers = {
      headers: { Authorization: "Bearer " + user.token },
    };
    patchMemberhol(
      `/api/v1/members/${state.pubkey}`,
      {
        phone: state.phone,
        full_name: state.full_name,
        date_of_birth: state.date_of_birth,
        status: currentMember.status,
        loan_service_charge: currentMember.loan_service_charge,
        member_role: state.member_role,
        gender: state.gender,
        nationalities: state.nationalities,
      },
      headers
    )
      .then((res) => {
        updateMemberInfo(res);
        fetchMembers();
        setIsEditing(false);
        notify(<SuccessMessage />);
      })
      .catch((error) => {
        notify(<ErrorMessage />);
      });
  };

  const [hasActiveMeeting, setHasActiveMeeting] = React.useState(false);
  const [canEdit, setCanEdit] = React.useState(false);

  const cachedMeeting = useLiveQuery(async () => {
    try {
      const items = await db.offlineMeeting.toArray();
      return items.filter(({ vslaId }) => vslaId === vsla.id);
    } catch (error) {
      console.log(`Error: ${error}`);
      return [];
    }
  }, []);

  const [offlineMeeting, setOfflineMeeting] = React.useState(null);

  React.useEffect(() => {
    if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
      return;
    }
    setOfflineMeeting({
      ...cachedMeeting[0].data,
      cacheId: cachedMeeting[0].cacheId,
    });
  }, [cachedMeeting]);

  React.useEffect(() => {
    setHasActiveMeeting(
      [
        ongoingMeeting !== undefined && ongoingMeeting.length,
        offlineMeeting && offlineMeeting.status === "ongoing",
      ].some((value) => value)
    );

    setCanEdit(
      isConnected &&
        (ongoingMeeting === undefined || ongoingMeeting.length === 0) &&
        (!offlineMeeting || offlineMeeting.status === "completed")
    );
  }, [isConnected, ongoingMeeting, offlineMeeting]);

  const hideMidCycleCashoutTab = () => {
    const memId = params.mem_id;
    const lang = localStorage.getItem("lang");
    const balances = account;
    const hasPositiveBalance =
      balances.savings.balance > 0 ||
      balances["Fine paid"].balance > 0 ||
      balances.welfare.balance > 0 ||
      balances.membership.balance > 0;
    const shouldHide =
      !processingCashout(memId, params.vsla_id) &&
      hasPositiveBalance &&
      !offlineMeeting &&
      !["ARABIC", "LEBANESE"].includes(lang);

    return shouldHide;
  };

  const performMidCycleCashout = () => {
    setPage("group-cashOut");
  };
  return (
    <>
      {isLoading && <StellarLoader />}

      <div>
        <div>
          <h4 style={headerStyle}>{currentMember.full_name}</h4>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 15px",
          }}
        >
          <span style={{ float: "right" }}>
            {Array.isArray(cachedTransactions) &&
              cachedTransactions.length > 0 && (
                <span
                  className="dots-loader"
                  style={{
                    color: "#E36F1E",
                    fontSize: 12,
                    margin: "auto",
                  }}
                >
                  {getTranslation("span", "processing")}
                  <span style={{ position: "absolute" }}></span>
                </span>
              )}
          </span>
        </div>
        <Container className="list-group-flush">
          <ListGroup>
            <span
              onClick={() => {
                setPage("group-overview");
              }}
            >
              <ListGroupItem
                className="textStyle"
                style={
                  localStorage.getItem("lang") === "ARABIC"
                    ? linkReverseStyles
                    : { ...loadingStyle, color: "#000" }
                }
              >
                <Translate content="menu_mem.overview" />
                <FontAwesomeIcon style={iconStyle} icon="angle-right" />
              </ListGroupItem>
            </span>

            {hasActiveMeeting && (
              <div>
                <span
                  onClick={() => {
                    setPage("group-buyShares");
                  }}
                >
                  <ListGroupItem
                    style={
                      localStorage.getItem("lang") === "ARABIC"
                        ? linkReverseStyles
                        : { color: "#000" }
                    }
                    className="textStyle"
                  >
                    <Translate
                      content="menu_mem.buy_shares"
                      style={loadingStyle}
                    />
                    <FontAwesomeIcon style={iconStyle} icon="angle-right" />
                  </ListGroupItem>
                </span>
                {availableAccounts.flat(1).includes("welfare") &&
                  !["ARABIC", "LEBANESE"].includes(
                    localStorage.getItem("lang")
                  ) && (
                    <span
                      onClick={() => {
                        setPage("group-addWelfare");
                      }}
                    >
                      <ListGroupItem
                        style={
                          localStorage.getItem("lang") === "ARABIC"
                            ? linkReverseStyles
                            : { color: "#000" }
                        }
                        className="textStyle"
                      >
                        <Translate
                          content="menu_mem.welfare"
                          style={loadingStyle}
                        />
                        <FontAwesomeIcon style={iconStyle} icon="angle-right" />
                      </ListGroupItem>
                    </span>
                  )}
                {availableAccounts.flat(1).includes("fine") &&
                  !["ARABIC", "LEBANESE"].includes(
                    localStorage.getItem("lang")
                  ) && (
                    <span
                      onClick={() => {
                        setPage("group-payFine");
                      }}
                    >
                      <ListGroupItem
                        style={
                          localStorage.getItem("lang") === "ARABIC"
                            ? linkReverseStyles
                            : { color: "#000" }
                        }
                        className="textStyle"
                      >
                        <Translate
                          content="menu_mem.pay_fine"
                          style={loadingStyle}
                        />
                        <FontAwesomeIcon style={iconStyle} icon="angle-right" />
                      </ListGroupItem>
                    </span>
                  )}
                {!["ARABIC", "LEBANESE"].includes(
                  localStorage.getItem("lang")
                ) &&
                  account.loan.balance === 0 &&
                  !offlineMeeting && (
                    <span
                      onClick={(e) => {
                        if (isCreatingLoan) {
                          e.preventDefault();
                          return;
                        }
                        setPage("group-createLoan");
                      }}
                    >
                      <ListGroupItem
                        style={{
                          ...(localStorage.getItem("lang") === "ARABIC"
                            ? linkReverseStyles
                            : {}),
                          ...{
                            color: isCreatingLoan ? "grey" : "#000",
                          },
                        }}
                        className="textStyle"
                      >
                        <Translate
                          content="menu_mem.create_loan"
                          style={loadingStyle}
                        />
                        <FontAwesomeIcon style={iconStyle} icon="angle-right" />
                      </ListGroupItem>
                    </span>
                  )}
                {account.shares.balance !== 0 && !offlineMeeting && (
                  <span
                    onClick={(e) => {
                      if (isSellingShares) {
                        e.preventDefault();
                        return;
                      }
                      setPage("group-sellShares");
                    }}
                  >
                    <ListGroupItem
                      style={{
                        ...(localStorage.getItem("lang") === "ARABIC"
                          ? linkReverseStyles
                          : {}),
                        ...{
                          color: isSellingShares ? "grey" : "#000",
                        },
                      }}
                      className="textStyle"
                    >
                      <Translate
                        content="menu_mem.sell_shares"
                        style={loadingStyle}
                      />
                      <FontAwesomeIcon style={iconStyle} icon="angle-right" />
                    </ListGroupItem>
                  </span>
                )}
                {(account.loan.balance > 0 ||
                  reversedLoanRepaidTransaction.length > 0) &&
                  !offlineMeeting &&
                  !["ARABIC", "LEBANESE"].includes(
                    localStorage.getItem("lang")
                  ) && (
                    <span
                      onClick={(e) => {
                        if (isPayingLoan || isPayingFee) {
                          e.preventDefault();
                          return;
                        }
                        setPage("group-repayLoan");
                      }}
                    >
                      <ListGroupItem
                        style={{
                          ...(localStorage.getItem("lang") === "ARABIC"
                            ? linkReverseStyles
                            : {}),
                          ...{
                            color:
                              isPayingLoan || isPayingFee ? "grey" : "#000",
                          },
                        }}
                        className="textStyle"
                      >
                        <Translate
                          content="menu_mem.repay_loan"
                          style={loadingStyle}
                        />
                        <FontAwesomeIcon style={iconStyle} icon="angle-right" />
                      </ListGroupItem>
                    </span>
                  )}
                {availableAccounts.flat(1).includes("welfare") &&
                  !["ARABIC", "LEBANESE"].includes(
                    localStorage.getItem("lang")
                  ) &&
                  !offlineMeeting && (
                    <span
                      onClick={(e) => {
                        if (isRequestingWellFare) {
                          e.preventDefault();
                          return;
                        }
                        setPage("group-requestWelfare");
                      }}
                    >
                      <ListGroupItem
                        style={{
                          ...(localStorage.getItem("lang") === "ARABIC"
                            ? linkReverseStyles
                            : {}),
                          ...{
                            color: isRequestingWellFare ? "grey" : "#000",
                          },
                        }}
                        className="textStyle"
                      >
                        <Translate
                          content="menu_mem.welfare_request"
                          style={loadingStyle}
                        />
                        <FontAwesomeIcon style={iconStyle} icon="angle-right" />
                      </ListGroupItem>
                    </span>
                  )}
                {availableAccounts.flat(1).includes("membership") &&
                  !["ARABIC", "LEBANESE"].includes(
                    localStorage.getItem("lang")
                  ) && (
                    <span
                      onClick={() => {
                        setPage("group-membershipFee");
                      }}
                    >
                      <ListGroupItem
                        style={
                          localStorage.getItem("lang") === "ARABIC"
                            ? linkReverseStyles
                            : { color: "#000" }
                        }
                        className="textStyle"
                      >
                        <Translate
                          content="menu_mem.membership_fee"
                          style={loadingStyle}
                        />
                        <FontAwesomeIcon style={iconStyle} icon="angle-right" />
                      </ListGroupItem>
                    </span>
                  )}

                {account.loan.balance > 0 &&
                  !offlineMeeting &&
                  !["ARABIC", "LEBANESE"].includes(
                    localStorage.getItem("lang")
                  ) && (
                    <span
                      onClick={(e) => {
                        if (isWritingOffLoan) {
                          e.preventDefault();
                          return;
                        }
                        setPage("group-writeOffLoan");
                      }}
                    >
                      <ListGroupItem
                        style={{
                          ...(localStorage.getItem("lang") === "ARABIC"
                            ? linkReverseStyles
                            : {}),
                          ...{
                            color: isWritingOffLoan ? "grey" : "#000",
                          },
                        }}
                        className="textStyle"
                      >
                        <Translate
                          content="menu_mem.writeoff_loan"
                          style={{
                            fontFamily: "Fira Sans",
                            fontSize: "20px",
                          }}
                        />
                        <FontAwesomeIcon style={iconStyle} icon="angle-right" />
                      </ListGroupItem>
                    </span>
                  )}
                {!["ARABIC", "LEBANESE"].includes(
                  localStorage.getItem("lang")
                ) && (
                  <>
                    <span
                      onClick={() => {
                        setPage("group-addGroupIncome");
                      }}
                    >
                      <ListGroupItem
                        style={
                          localStorage.getItem("lang") === "ARABIC"
                            ? linkReverseStyles
                            : { color: "#000" }
                        }
                        className="textStyle"
                      >
                        <Translate
                          content="menu_mem.other_income"
                          style={{
                            fontFamily: "Fira Sans",
                            fontSize: "20px",
                          }}
                        />
                        <FontAwesomeIcon style={iconStyle} icon="angle-right" />
                      </ListGroupItem>
                    </span>
                    {!offlineMeeting && (
                      <span
                        onClick={() => {
                          setPage("group-addGroupExpense");
                        }}
                      >
                        <ListGroupItem
                          style={
                            localStorage.getItem("lang") === "ARABIC"
                              ? linkReverseStyles
                              : { color: "#000" }
                          }
                          className="textStyle"
                        >
                          <Translate
                            content="menu_mem.other_expenses"
                            style={{
                              fontFamily: "Fira Sans",
                              fontSize: "20px",
                            }}
                          />
                          <FontAwesomeIcon
                            style={iconStyle}
                            icon="angle-right"
                          />
                        </ListGroupItem>
                      </span>
                    )}
                  </>
                )}
                {hideMidCycleCashoutTab() && (
                  <span
                    onClick={() => {
                      setIsOpen(true);
                    }}
                    style={{ touchAction: "none", cursor: "pointer" }}
                  >
                    <ListGroupItem
                      style={
                        localStorage.getItem("lang") === "ARABIC"
                          ? linkReverseStyles
                          : { color: "#000" }
                      }
                      className="textStyle"
                    >
                      <Translate
                        content="menu_mem.midCycle_Cashout"
                        style={{
                          fontFamily: "Fira Sans",
                          fontSize: "20px",
                        }}
                      />
                      <FontAwesomeIcon style={iconStyle} icon="angle-right" />
                    </ListGroupItem>
                  </span>
                )}
              </div>
            )}
          </ListGroup>
        </Container>
        {!hasActiveMeeting && (
          <>
            <br />
            <div>
              <Translate
                content="title_copy.mem_actions"
                component="h6"
                style={actionStyle}
              />
            </div>
            <Container>
              {isEditing && (
                <>
                  <span
                    style={{
                      direction: "ltr",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Label
                      style={{
                        ...loadingStyle,
                        alignSelf:
                          localStorage.getItem("lang") === "ARABIC"
                            ? "end"
                            : "initial",
                      }}
                    ></Label>
                    <PhoneInput
                      country="ne"
                      enableSearch
                      disableSearchIcon
                      placeholder={member_phone}
                      style={loadingStyle}
                      inputStyle={{
                        flex: "10",
                        fontFamily: "Fira Sans",
                        height: "50px",
                        width: "100%",
                        fontSize: "20px",
                      }}
                      name="phone"
                      onChange={(phone) => setState({ ...state, phone })}
                      value={state.phone}
                      limitMaxLength={true}
                      localization={
                        localStorage.getItem("lang") === "ARABIC" ? ar : false
                      }
                    />
                  </span>
                  <Input
                    type="text"
                    name="full_name"
                    style={inputStyle}
                    placeholder={member_name}
                    onChange={onChange}
                    value={state.full_name}
                  />

                  <Input
                    type="date"
                    name="date_of_birth"
                    placeholder="date_of_birth"
                    selected={state.date_of_birth}
                    style={{ ...formStyle, margin: "8px 0" }}
                    onChange={onChange}
                    defaultValue={state.date_of_birth}
                    numberofmonths={1}
                    max={defaultDate()}
                  />
                  <Input
                    id="select_role"
                    type="select"
                    name="member_role"
                    style={formStyle}
                    onChange={onChange}
                    value={state.member_role}
                  >
                    <option value="">{state.member_role}</option>
                    <option>{secretary}</option>
                    <option>{chairperson}</option>
                    <option>{box_keeper}</option>
                    <option>{money_counter}</option>
                    <option>{member}</option>
                    <option>{keyHolder}</option>
                  </Input>

                  {["ARABIC", "LEBANESE"].includes(
                    localStorage.getItem("lang")
                  ) && (
                    <MultipleSelect
                      title={nationality}
                      preSetItems={state.nationalities}
                      callback={(items) => {
                        setState({ ...state, nationalities: items });
                      }}
                      placeholderOption={select_nationality}
                      options={[
                        {
                          value: getTranslation("countries", "Lebanon"),
                          title: getTranslation("countries", "Lebanon"),
                        },
                        {
                          value: getTranslation("countries", "Syria"),
                          title: getTranslation("countries", "Syria"),
                        },
                        {
                          value: getTranslation("countries", "Palestine"),
                          title: getTranslation("countries", "Palestine"),
                        },
                      ]}
                    />
                  )}
                  <Input
                    id="gender"
                    type="select"
                    name="gender"
                    style={{ ...formStyle, margin: "8px 0" }}
                    onChange={onChange}
                    value={state.gender || null}
                  >
                    <option disabled value="">
                      {gender}
                    </option>
                    <option value="female">{female}</option>
                    <option value="male">{male}</option>
                  </Input>
                  <br />
                  <Button
                    className="btn btn-primary"
                    onClick={updateMember}
                    size="sm"
                    color="primary"
                    block
                    disabled={editButtonDisable}
                  >
                    <Translate content="save" />
                  </Button>
                </>
              )}
              {!isEditing && (
                <Button
                  className="btn btn-primary"
                  onClick={toggleEditMember}
                  size="sm"
                  color="primary"
                  block
                  disabled={!canEdit}
                >
                  <Translate content="edit_member" />
                </Button>
              )}
              <Button
                className="btn btn-danger"
                onClick={deleteMember}
                size="sm"
                block
                disabled={deactivateDeleteBtn()}
                style={{ marginTop: "10px" }}
              >
                <Translate content="delete_member" />
              </Button>
            </Container>{" "}
          </>
        )}
      </div>
      <CustomModal
        modalHeader={getTranslation("title_copy", "warning")}
        headerClassName="d-flex justify-content-center"
        bodyContent={
          <div>
            {getTranslation("notifications", "mid_cycle_cash_out_warning")}
            <br />
            <br />
            {getTranslation("notifications", "proceed_to_cashout_msg")}
          </div>
        }
        isCentered={true}
        showInput={true}
        errors={{}}
        showSavingsCycle={false}
        isCashoutModal={true}
        setInputData={setInputData}
        isOpen={isOpen}
        footerContent={
          <div className="btn-toolbar">
            <button
              className="btn"
              onClick={() => {
                setIsOpen(false);
              }}
              style={BackbuttonStyle}
            >
              {getTranslation("span", "back")}
            </button>
            <button
              className="btn btn-info"
              onClick={() => performMidCycleCashout()}
              style={ContbuttonStyle}
              disabled={
                !(modalInputData === getTranslation("notifications", "cashout"))
              }
            >
              {getTranslation("span", "continue")}
            </button>
          </div>
        }
      />
    </>
  );
};

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
};
const BackbuttonStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "6px 12px",
  width: "62px",
  height: "36px",
  color: "#fff",
  fontFamily: "Quicksand",
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 600,
  background: "#6C757D",
  borderRadius: "4px",
  flex: "none",
  border: "0",
  alignSelf: "stretch",
  flexGrow: "0",
  textTransform: "capitalize",
  marginRight: "0.5em",
};

const ContbuttonStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "6px 12px",
  width: "93px",
  height: "36px",
  color: "#fff",
  fontFamily: "Quicksand",
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 600,
  background: "#E36F1E",
  borderRadius: "4px",
  flex: "none",
  border: "0",
  alignSelf: "stretch",
  flexGrow: "0",
  textTransform: "capitalize",
  marginRight: "0.5em",
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    isConnected: state.isConnected,
    currentPage: state.currentPage,
    vsla: state.vsla,
    vslasThatIncludesBlockchainKey: state.loadedvslas,
    meetingTransactions: state.meetingTransactions.filter(({ requestId }) =>
      requestId.includes(state.loadedMember.pubkey)
    ),
    pendingTransactions: state.pendingTransactions,
    pendingTransactionsKeys: Object.keys(state.pendingTransactions).filter(
      (key) => {
        const fractions = key.split("_");
        return fractions.length >= 3 && fractions.includes(state.vsla.id);
      }
    ),
    pendingKeys: Object.keys(state.pendingTransactions)
      .filter((key) => {
        const fractions = key.split("_");
        return fractions.length >= 3 && fractions.includes(state.vsla.id);
      })
      .map((key) => {
        const fractions = key.split("_");
        return `${fractions[fractions.length - 2]}_${
          fractions[fractions.length - 1]
        }`;
      }),
    processingCashout: (memId, vslaId) =>
      state.pendingTransactions[`${vslaId}_${memId}_cashout`],
    isLoading: state.memberBalanceLoadStarted,
    isLoadingMember: state.memberLoadingStarted,
    fund_balance: state.memberBalanceLoaded,
    ongoingMeeting: state.currentOngoingMeeting,
    currentMember: state.loadedMember,
    loadedvslaMembers: state.loadedvslaMembers,
    membersBalance: state.account.members,
    availableAccounts: selectVlsaFundAccounts(state),
    reversedLoanRepaidTransaction:
      selectMemberReversedLoanPaidTrasactions(state),
    cashoutStarted: state.cashoutStarted,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPage: (page) => dispatch(setCurrentPage(page)),
    // getMemberBalance: url => dispatch(fetchMemberBalance(url)),
    getMemberBalance: (id) => dispatch(getMemberBalanceByMemberId(id)),
    getUpdatedVslaBalance: (url, headers) =>
      dispatch(itemsFetchBalances(url, headers)),
    getAggregates: (headers) => dispatch(fetchTransactionsAggreg(headers)),
    patchMemberhol: (url, data, headers) =>
      dispatch(patchMemberHOL(url, data, headers)),
    fetchMembers: () => dispatch(fetchVslasMembers()),
    updateMemberInfo: (data) => dispatch(memberLoaded(data)),
    deleteUser: (member, data, vsla_id) =>
      dispatch(deleteMemberAction(member, data, vsla_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
